<template>
  <Content
    class="mx-auto max-w-2xl columns-2 gap-6 sm:columns-3 dt:grid dt:max-w-4xl dt:grid-cols-5">
    <NuxtLink
      v-for="(category, index) in categories"
      :key="index"
      :to="
        localePath({
          name: 'programs-category',
          params: {
            category: category.id + ''
          }
        })
      "
      class="rounded-[32px] mb-6 inline-flex w-full flex-col items-center justify-center gap-y-3 p-4 dt:mb-0"
      :style="{ backgroundColor: category.color }">
      <NuxtImg
        v-if="category.image"
        format="webp"
        sizes="sm:100px"
        :src="category.image"
        provider="directus"
        width="80"
        height="80"
        :alt="translate(category)?.title" />
      <div class="text-center text-base font-bold">
        {{ translate(category)?.name }}
      </div>
    </NuxtLink>
  </Content>
</template>

<script lang="ts" setup>
import { translateItem } from '@/composables/use-translations'
const localePath = useLocalePath()

const config = useRuntimeConfig()

const { translate } = useTranslator()

defineProps<{
  categories: Category[]
}>()
</script>
